import React from 'react';

export default props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="
      M14.829 6.302
      c-.738-.034-.96-.04-2.829-.04
      s-2.09.007-2.828.04
      c-1.899.087-2.783.986-2.87 2.87-.033.738-.041.959-.041 2.828
      s.008 2.09.041 2.829
      c.087 1.879.967 2.783 2.87 2.87.737.033.959.041 2.828.041 1.87 0 2.091-.007 2.829-.041 1.899-.086 2.782-.988 2.87-2.87.033-.738.04-.96.04-2.829
      s-.007-2.09-.04-2.828
      c-.088-1.883-.973-2.783-2.87-2.87
      z
      m-2.829 9.293
      c-1.985 0-3.595-1.609-3.595-3.595 0-1.985 1.61-3.594 3.595-3.594
      s3.595 1.609 3.595 3.594
      c0 1.985-1.61 3.595-3.595 3.595
      z
      m3.737-6.491
      c-.464 0-.84-.376-.84-.84 0-.464.376-.84.84-.84.464 0 .84.376.84.84 0 .463-.376.84-.84.84
      z
      m-1.404 2.896
      c0 1.289-1.045 2.333-2.333 2.333
      s-2.333-1.044-2.333-2.333
      c0-1.289 1.045-2.333 2.333-2.333
      s2.333 1.044 2.333 2.333
      z
      m-2.333-12
      c-6.627 0-12 5.373-12 12
      s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12
      z
      m6.958 14.886
      c-.115 2.545-1.532 3.955-4.071 4.072-.747.034-.986.042-2.887.042
      s-2.139-.008-2.886-.042
      c-2.544-.117-3.955-1.529-4.072-4.072-.034-.746-.042-.985-.042-2.886 0-1.901.008-2.139.042-2.886.117-2.544 1.529-3.955 4.072-4.071.747-.035.985-.043 2.886-.043
      s2.14.008 2.887.043
      c2.545.117 3.957 1.532 4.071 4.071.034.747.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886
      z"
    />
  </svg>
);
