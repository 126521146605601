import React from 'react';

export default props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="
      M12 2
      c5.514 0 10 4.486 10 10
      s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10
      zm0-2
      c-6.627 0-12 5.373-12 12
      s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12
      zm0 7.082
      c1.602 0 1.792.006 2.425.035 1.627.074 2.385.845 2.46 2.459.028.633.034.822.034 2.424
      s-.006 1.792-.034 2.424
      c-.075 1.613-.832 2.386-2.46 2.46-.633.028-.822.035-2.425.035-1.602 0-1.792-.006-2.424-.035-1.63-.075-2.385-.849-2.46-2.46-.028-.632-.035-.822-.035-2.424
      s.007-1.792.035-2.424
      c.074-1.615.832-2.386 2.46-2.46.632-.029.822-.034 2.424-.034
      zm0-1.082
      c-1.63 0-1.833.007-2.474.037-2.18.1-3.39 1.309-3.49 3.489-.029.641-.036.845-.036 2.474 0 1.63.007 1.834.036 2.474.1 2.179 1.31 3.39 3.49 3.49.641.029.844.036 2.474.036 1.63 0 1.834-.007 2.475-.036 2.176-.1 3.391-1.309 3.489-3.49.029-.64.036-.844.036-2.474 0-1.629-.007-1.833-.036-2.474-.098-2.177-1.309-3.39-3.489-3.489-.641-.03-.845-.037-2.475-.037
      zm0 2.919
      c-1.701 0-3.081 1.379-3.081 3.081
      s1.38 3.081 3.081 3.081 3.081-1.379 3.081-3.081
      c0-1.701-1.38-3.081-3.081-3.081
      zm0 5.081
      c-1.105 0-2-.895-2-2 0-1.104.895-2 2-2 1.104 0 2.001.895 2.001 2
      s-.897 2-2.001 2
      zm3.202-5.922
      c-.397 0-.72.322-.72.72 0 .397.322.72.72.72.398 0 .721-.322.721-.72 0-.398-.322-.72-.721-.72
      z"
    />
  </svg>
);
